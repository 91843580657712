import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import { IoIosArrowForward } from "react-icons/io"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Cover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  object-fit: cover;
`
const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`
const Wrapper = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  padding: 30px;

  @media (max-width: 750px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 650px) {
    margin-top: 100px;
  }

  @media (max-width: 420px) {
    margin-top: 350px;
  }

  @media (max-width: 360px) {
    margin-top: 460px;
  }
`
const Text = styled.h3`
  font-family: "alternategothic2_btregular";
  color: white;
  font-size: 24px;
  max-width: 400px;
  line-height: 150%;
  margin: 0 50px 0 0;
  font-weight: 400;
  font-style: normal;
  @media (max-width: 750px) {
    margin: 0 0 50px 0;
  }
`
const NextButton = styled.div`
  align-self: auto;
  position: absolute;
  right: 20px;
  top: 50%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: "alternategothic2_btregular";
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
`

class AboutPage extends Component {
  state = {}
  render() {
    const pageData = this.props.data.allWordpressPage.edges[0].node
    return (
      <Layout>
        <SEO title="About" />
        <PageHeader>About</PageHeader>
        <Cover src={pageData.acf.cover.source_url} />
        <Content>
          <Wrapper>
            <Text>
              T&T ROCKS is an ideas-based production company born out of our
              love for Trinidad & Tobago and the wider Caribbean, and a desire
              to capture the moments of everyday life here, whether big or
              small. We apply the philosophy that there is beauty everywhere to
              the work that we produce for our clients, and for the world.​
            </Text>
            <Text>
              Our creative lead, Miquel Galofré, has documentary filmmaking
              roots, which helps to bring an authenticity to our work. For every
              new project, we hand-pick collaborators from some of the most
              talented minds the region has to offer. The result? A custom team
              perfectly suited to your needs and the job at hand.
            </Text>
            {/* <NextButton>
              <h1>Our Team</h1> <IoIosArrowForward size={40} />
            </NextButton> */}
          </Wrapper>
        </Content>
      </Layout>
    )
  }
}

export default AboutPage

export const aboutQuery = graphql`
  query($homepage: String = "About") {
    allWordpressPage(filter: { title: { eq: $homepage } }) {
      edges {
        node {
          title
          acf {
            cover {
              source_url
              alt_text
            }
          }
        }
      }
    }
  }
`
